import React, { useCallback, useState } from "react";

import type {
  AxiosAPIError,
  ConnectAdminCreateCompanyWithUsersRequest,
  ConnectAdminCreateCompanyWithUsersResponse,
  UsersResponse,
} from "@meterup/api";
import { axios, CompanyMembershipRole } from "@meterup/api";
import {
  Alert,
  Badge,
  BasicSelect,
  BasicSelectItem,
  Body2,
  Button,
  CloseDrawerButton,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  FieldContainer,
  HStack,
  PrimaryField,
  Rule,
  SecondaryField,
  Text,
  Textarea,
  ToggleInput,
} from "@meterup/metric";
import { api } from "@meterup/proto";
import { CloseDrawerLink } from "@meterup/ui";
import { UserListItem } from "@meterup/ui/src/components/UserListItem";
import useMetaSubmit from "@meterup/ui/src/hooks/useMetaSubmit";
import { SimpleList } from "@meterup/ui/src/styles/SimpleList";
import { createTypedField } from "@meterup/ui/src/utils/stolen-from-frontends/createTypedField";
import { pluralize } from "@meterup/ui/src/utils/string.ts/String.utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Formik } from "formik";
import take from "lodash/take";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { useCompanyOutletContext } from "../../../contexts/CompanyContext";
import { partialInviteUsers } from "../../../zod/partialInviteUsers";

const inviteUserSchema = partialInviteUsers;
interface InviteUsersFormData extends z.TypeOf<typeof inviteUserSchema> {}

const TypedField = createTypedField<InviteUsersFormData>();
type UsersResponseUsers = UsersResponse["users"];

export default function CreateUser() {
  const [formData, setFormData] = useState<InviteUsersFormData>();
  const { companySID: slug } = useParams();
  const queryClient = useQueryClient();
  const onMetaSubmit = useMetaSubmit();
  const { company, users } = useCompanyOutletContext();
  // const { toInvite, notToInvite } = useMemo(() => {
  //   const existingUsers = users?.users || ([] as UsersResponseUsers);
  //   const formUsers = (formData?.users || []).map((email) => ({ email }));
  //   if (existingUsers.length === 0) {
  //     return {
  //       toInvite: formUsers,
  //       notToInvite: [],
  //     };
  //   }
  //   const uToInvite = existingUsers.filter((user) => !formUsers.includes(user.email));
  //   const uNotToInvite = existingUsers.filter((user) => formUsers.includes(user.email));
  //   return {
  //     toInvite: uToInvite,
  //     notToInvite: uNotToInvite,
  //   };
  // }, [formData, users]);
  const mutation = useMutation<
    ConnectAdminCreateCompanyWithUsersResponse,
    AxiosAPIError,
    ConnectAdminCreateCompanyWithUsersRequest
  >(
    (request) =>
      axios.post(`v1/connect/admin/companies/${encodeURIComponent(slug)}/users`, request),
    {
      onSuccess() {
        queryClient.invalidateQueries(["companyUsers", slug]);
      },
    },
  );
  const onBack = useCallback(
    (e) => {
      e.preventDefault();
      setFormContent(undefined);
      mutation.reset();
    },
    [mutation],
  );
  const onAdd = useCallback(
    (e) => {
      e.preventDefault();
      if (formData) {
        mutation.mutate({
          sendInvite: formData.sendInvite,
          users: {
            users: formData.users.map((user) => ({
              ...user,
              companyRole: formData.role,
            })),
          },
        });
      }
    },
    [formData, mutation],
  );
  const onSubmit = useCallback((values: InviteUsersFormData) => {
    const { data, success } = inviteUserSchema.safeParse(values);
    if (!success) {
      return;
    }
    setFormData(data);
  }, []);
  if (mutation.isSuccess && mutation.data?.company) {
    return (
      <div>
        <DrawerHeader>
          <DrawerTitle>Add company</DrawerTitle>
          <DrawerControls>
            <CloseDrawerLink>
              <CloseDrawerButton />
            </CloseDrawerLink>
          </DrawerControls>
        </DrawerHeader>
        <DrawerContent>
          <Alert
            icon="checkmarkCircle"
            variant="positive"
            heading={`${pluralize(mutation.data.users.results.length, "user")} invited to ${
              company?.name
            }`}
          />
          <Rule height={2} />
          <HStack align="end" display="flex" spacing={8} justify="end">
            <CloseDrawerLink>
              <Button variant="secondary" type="button">
                Close
              </Button>
            </CloseDrawerLink>
          </HStack>
        </DrawerContent>
      </div>
    );
  }
  if (formData) {
    const errorData = mutation.error?.response?.data;
    return (
      <div>
        <DrawerHeader>
          <DrawerTitle>Invite users</DrawerTitle>
          <DrawerControls>
            <CloseDrawerLink>
              <CloseDrawerButton />
            </CloseDrawerLink>
          </DrawerControls>
        </DrawerHeader>
        <DrawerContent>
          {mutation.isError && errorData ? (
            <Alert
              icon="warning"
              variant="negative"
              heading="Failed to invite users"
              copy={
                <dl>
                  {take(Object.keys(errorData), 6).map((k) => (
                    <>
                      <dt>{k}</dt>
                      <dd>{errorData[k]}</dd>
                    </>
                  ))}
                </dl>
              }
            />
          ) : null}
          <Body2>
            The following email addresses will be invited to{" "}
            <Text weight="medium">{company?.name}</Text> with{" "}
            <Badge>
              <Text transform="capitalize">{formData.role}</Text>
            </Badge>{" "}
            permissions:
          </Body2>
          <SimpleList>
            {(formData?.users || []).map((emailWithName) => (
              <UserListItem key={`uli-${emailWithName.email}`} user={emailWithName} />
            ))}
          </SimpleList>
          <Rule height={2} />
          <HStack align="end" display="flex" spacing={8} justify="end">
            <Button variant="secondary" type="button" onClick={onBack}>
              Back
            </Button>
            <Button variant="primary" type="button" onClick={onAdd}>
              Add
            </Button>
          </HStack>
        </DrawerContent>
      </div>
    );
  }
  return (
    <Formik<InviteUsersFormData>
      initialValues={{ sendInvite: false, role: CompanyMembershipRole.admin, users: "" }}
      validationSchema={toFormikValidationSchema(inviteUserSchema)}
      onSubmit={onSubmit}>
      {(form) => (
        <form onSubmit={form.handleSubmit} onKeyDown={onMetaSubmit(form.handleSubmit)}>
          <DrawerHeader>
            <DrawerTitle>Invite users</DrawerTitle>
            <DrawerControls>
              <CloseDrawerLink>
                <CloseDrawerButton />
              </CloseDrawerLink>
            </DrawerControls>
          </DrawerHeader>
          <DrawerContent>
            <FieldContainer>
              <TypedField name="users">
                {({ field }) => (
                  <PrimaryField
                    label="Users"
                    errorMessage={form.touched.users && form.errors.users}
                    element={
                      <Textarea
                        placeholder="Email(s), comma or whitespace separated"
                        value={field.value}
                        id={field.name}
                        name={field.name}
                        onChange={field.onChange}
                        autoComplete="off"
                        hasError={form.touched.users && form.errors.users}
                      />
                    }
                    description="Can use GMail contact format, e.g: First Last <first@last.com>"
                  />
                )}
              </TypedField>
              <TypedField name="role">
                {({ field }) => (
                  <SecondaryField
                    label="Role"
                    errorMessage={form.touched.role && form.errors.role}
                    element={
                      <BasicSelect
                        value={field.value}
                        id={field.name}
                        name={field.name}
                        onChange={(v) => form.setFieldValue(field.name, v)}
                        autoComplete="off"
                        hasError={form.touched.role && form.errors.role}>
                        <BasicSelectItem value={CompanyMembershipRole.member}>
                          Member
                        </BasicSelectItem>
                        <BasicSelectItem value={CompanyMembershipRole.admin}>Admin</BasicSelectItem>
                      </BasicSelect>
                    }
                  />
                )}
              </TypedField>
              <TypedField name="sendInvite">
                {({ field }) => (
                  <SecondaryField
                    label="Send invite"
                    errorMessage={form.touched.sendInvite && form.errors.sendInvite}
                    element={
                      <div>
                        <ToggleInput
                          aria-label="Select whether to send an invite when creating the users in the company"
                          selected={field.value}
                          onChange={(v) => form.setFieldValue(field.name, v)}
                          defaultSelected
                        />
                      </div>
                    }
                    hasError={form.touched.sendInvite && form.errors.sendInvite}
                  />
                )}
              </TypedField>
            </FieldContainer>
            <Rule height={2} />
            <HStack align="end" display="flex" spacing={8} justify="end">
              <CloseDrawerLink>
                <Button variant="secondary" type="button">
                  Cancel
                </Button>
              </CloseDrawerLink>
              <Button variant="primary" type="submit">
                Next
              </Button>
            </HStack>
          </DrawerContent>
        </form>
      )}
    </Formik>
  );
}
