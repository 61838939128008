import { CompanyMembershipRole } from "@meterup/api/src/apiProtos";
import { parseEmails } from "@meterup/ui/src/utils/parseEmails";
import { z } from "zod";

const partialInviteUsers = z.object({
  users: z.string().transform((val, ctx) => {
    const emailWithNames = parseEmails(val);
    if (emailWithNames.length === 0) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, message: "No valid emails found." });
      return z.NEVER;
    }
    return emailWithNames;
  }),
  role: z.enum([CompanyMembershipRole.admin, CompanyMembershipRole.member]),
  sendInvite: z.boolean(),
});

export { partialInviteUsers };
