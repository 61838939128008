import React, { useCallback, useState } from "react";

import type {
  AxiosAPIError,
  ConnectAdminCreateCompanyWithUsersRequest,
  ConnectAdminCreateCompanyWithUsersResponse,
} from "@meterup/api";
import { axios, CompanyMembershipRole } from "@meterup/api";
import {
  Alert,
  Badge,
  BasicSelect,
  BasicSelectItem,
  Body2,
  Button,
  CloseDrawerButton,
  DrawerContent as DrawerContentBase,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  FieldContainer,
  HStack,
  PrimaryField,
  Rule,
  SecondaryField,
  Text,
  Textarea,
  TextInput,
  ToggleInput,
} from "@meterup/metric";
import { CloseDrawerLink, styled } from "@meterup/ui";
import { UserListItem } from "@meterup/ui/src/components/UserListItem";
import useMetaSubmit from "@meterup/ui/src/hooks/useMetaSubmit";
import { SimpleList } from "@meterup/ui/src/styles/SimpleList";
import { createTypedField } from "@meterup/ui/src/utils/stolen-from-frontends/createTypedField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Formik } from "formik";
import take from "lodash/take";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { partialInviteUsers } from "../../../../zod/partialInviteUsers";

const DrawerContent = styled(DrawerContentBase, {
  // This is necessary because `FocusRingSelfIndexed` can take priority and drop the shadow.
  "& > div > div": {
    boxShadow: "$fenceAllLight",
    "&:only-child": {
      boxShadow: "unset",
    },
  },
});

function makeSlug(inputStr: string): string {
  // Replace non-alphanumeric characters with a dash
  let str = inputStr.trim().replace(/[^a-zA-Z0-9]/g, "-");
  // Convert to lowercase
  str = str.toLowerCase();
  // Remove leading and trailing dashes
  str = str.replace(/^-+|-+$/g, "");
  // Remove duplicate dashes
  str = str.replace(/-+/g, "-");
  return str;
}

function makeSlugOfLength(inputStr: string, length: number): string {
  const slug = makeSlug(inputStr);
  const uuidPart = uuid().split("-")[0];

  if (slug.length === 0) {
    return uuidPart;
  }

  if (slug.length < length) {
    return `${slug}-${uuidPart}`;
  }

  return slug;
}

const baseSchema = z.object({
  companyName: z.string().min(3),
  urlSlug: z
    .string()
    .min(3)
    .refine(
      (value) => value === makeSlug(value),
      (value) => ({
        message: `URL slug must be alphanumeric and lowercase, only containing dashes. ${
          value.length > 0 ? `You could try ${makeSlugOfLength(value, 5)}` : ""
        }`,
      }),
    ),
});

const zodSchema = baseSchema.merge(partialInviteUsers);

interface CreateCompanyFormData extends z.TypeOf<typeof zodSchema> {}

const TypedField = createTypedField<CreateCompanyFormData>();

export default function CreateCompany() {
  const [formContent, setFormContent] = useState<CreateCompanyFormData>();
  const queryClient = useQueryClient();
  const mutation = useMutation<
    ConnectAdminCreateCompanyWithUsersResponse,
    AxiosAPIError,
    ConnectAdminCreateCompanyWithUsersRequest
  >((request) => axios.post("v1/connect/admin/companies", request), {
    onSuccess() {
      queryClient.invalidateQueries(["admin", "companies"]);
    },
  });
  const onAdd = useCallback(
    (e) => {
      e.preventDefault();
      if (formContent) {
        mutation.mutate({
          company: {
            name: formContent.companyName,
            slug: formContent.urlSlug,
          },
          sendInvite: formContent.sendInvite,
          users: {
            users: formContent.users.map((user) => ({
              ...user,
              companyRole: formContent.role,
            })),
          },
        });
      }
    },
    [formContent, mutation],
  );
  const onBack = useCallback(
    (e) => {
      e.preventDefault();
      setFormContent(undefined);
      mutation.reset();
    },
    [mutation],
  );
  const onAddAnother = useCallback(
    (e) => {
      e.preventDefault();
      setFormContent(undefined);
      mutation.reset();
    },
    [mutation],
  );
  const onMetaSubmit = useMetaSubmit();
  const errorData = mutation.error?.response?.data;

  if (mutation.isSuccess && mutation.data?.company) {
    return (
      <div>
        <DrawerHeader>
          <DrawerTitle>Add company</DrawerTitle>
          <DrawerControls>
            <CloseDrawerLink>
              <CloseDrawerButton />
            </CloseDrawerLink>
          </DrawerControls>
        </DrawerHeader>
        <DrawerContent>
          <Alert
            icon="checkmarkCircle"
            variant="positive"
            heading={`Company ${mutation.data.company.name} created`}
          />
          <Link to={`/dashboard/companies/${mutation.data.company.slug}`}>
            View Company: {mutation.data.company.name}
          </Link>
          <Rule height={2} />
          <HStack align="end" display="flex" spacing={8} justify="end">
            <CloseDrawerLink>
              <Button variant="secondary" type="button">
                Close
              </Button>
            </CloseDrawerLink>
            <Button variant="primary" type="button" onClick={onAddAnother}>
              Add another company
            </Button>
          </HStack>
        </DrawerContent>
      </div>
    );
  }
  console.log("mutation", mutation, "formContent", formContent);
  if (formContent) {
    return (
      <div>
        <DrawerHeader>
          <DrawerTitle>Add company</DrawerTitle>
          <DrawerControls>
            <CloseDrawerLink>
              <CloseDrawerButton />
            </CloseDrawerLink>
          </DrawerControls>
        </DrawerHeader>
        <DrawerContent>
          {mutation.isError && errorData ? (
            <Alert
              icon="warning"
              variant="negative"
              heading="Failed to create company"
              copy={
                <dl>
                  {take(Object.keys(errorData), 6).map((k) => (
                    <>
                      <dt>{k}</dt>
                      <dd>{errorData[k]}</dd>
                    </>
                  ))}
                </dl>
              }
            />
          ) : null}
          <Body2>
            The following email addresses will be added to{" "}
            <Text weight="medium">{formContent.companyName}</Text> with{" "}
            <Badge>
              <Text transform="capitalize">{formContent.role}</Text>
            </Badge>{" "}
            permissions:
          </Body2>
          <SimpleList>
            {formContent.users.map((emailWithName) => (
              <UserListItem key={`uli-${emailWithName.email}`} user={emailWithName} />
            ))}
          </SimpleList>
          <Rule height={2} />
          <HStack align="end" display="flex" spacing={8} justify="end">
            <Button variant="secondary" type="button" onClick={onBack}>
              Back
            </Button>
            <Button variant="primary" type="button" onClick={onAdd}>
              Add
            </Button>
          </HStack>
        </DrawerContent>
      </div>
    );
  }
  return (
    <Formik<CreateCompanyFormData>
      initialValues={{
        companyName: "",
        role: CompanyMembershipRole.admin,
        sendInvite: false,
        urlSlug: "",
        users: "",
      }}
      enableReinitialize
      validationSchema={toFormikValidationSchema(zodSchema)}
      onSubmit={(values, ...rest) => {
        const parsed = zodSchema.parse(values);
        console.log("onSubmit", values, "rest", rest, "parsed", parsed);
        setFormContent(parsed);
      }}>
      {(form) => (
        <form onSubmit={form.handleSubmit} onKeyDown={onMetaSubmit(form.handleSubmit)}>
          <DrawerHeader>
            <DrawerTitle>Add company</DrawerTitle>
            <DrawerControls>
              <CloseDrawerLink onClose={form.resetForm}>
                <CloseDrawerButton />
              </CloseDrawerLink>
            </DrawerControls>
          </DrawerHeader>
          <DrawerContent>
            <FieldContainer>
              <TypedField name="companyName">
                {({ field }) => (
                  <PrimaryField
                    label="Company name"
                    errorMessage={form.touched.companyName && form.errors.companyName}
                    element={
                      <TextInput
                        value={field.value}
                        placeholder="Enter company name"
                        id={field.name}
                        name={field.name}
                        autoComplete="off"
                        onChange={(v) => {
                          if (form.values.urlSlug.length === 0 || !form.touched.urlSlug) {
                            form.setFieldValue("urlSlug", makeSlugOfLength(v, 5));
                          }
                          return form.setFieldValue(field.name, v);
                        }}
                        hasError={form.touched.companyName && form.errors.companyName}
                      />
                    }
                  />
                )}
              </TypedField>
            </FieldContainer>
            <FieldContainer>
              <TypedField name="urlSlug">
                {({ field }) => (
                  <PrimaryField
                    label="URL Slug"
                    errorMessage={form.touched.urlSlug && form.errors.urlSlug}
                    element={
                      <TextInput
                        placeholder="Enter slug"
                        value={field.value}
                        id={field.name}
                        name={field.name}
                        autoComplete="off"
                        onChange={(v) => form.setFieldValue(field.name, v)}
                        hasError={form.touched.urlSlug && form.errors.urlSlug}
                      />
                    }
                  />
                )}
              </TypedField>
              <TypedField name="users">
                {({ field }) => (
                  <PrimaryField
                    label="Users"
                    errorMessage={form.touched.users && form.errors.users}
                    element={
                      <Textarea
                        placeholder="Email(s), comma or whitespace separated"
                        value={field.value}
                        id={field.name}
                        name={field.name}
                        onChange={field.onChange}
                        autoComplete="off"
                        hasError={form.touched.users && form.errors.users}
                      />
                    }
                    description="Can use GMail contact format, e.g: First Last <first@last.com>"
                  />
                )}
              </TypedField>
              <TypedField name="role">
                {({ field }) => (
                  <SecondaryField
                    label="Role"
                    errorMessage={form.touched.role && form.errors.role}
                    element={
                      <BasicSelect
                        value={field.value}
                        id={field.name}
                        name={field.name}
                        onChange={(v) => form.setFieldValue(field.name, v)}
                        autoComplete="off"
                        hasError={form.touched.role && form.errors.role}>
                        <BasicSelectItem value={CompanyMembershipRole.member}>
                          Member
                        </BasicSelectItem>
                        <BasicSelectItem value={CompanyMembershipRole.admin}>Admin</BasicSelectItem>
                      </BasicSelect>
                    }
                  />
                )}
              </TypedField>
              <TypedField name="sendInvite">
                {({ field }) => (
                  <SecondaryField
                    label="Send invite"
                    errorMessage={form.touched.sendInvite && form.errors.sendInvite}
                    element={
                      <div>
                        <ToggleInput
                          aria-label="Select whether to send an invite when creating the user in the company"
                          selected={field.value}
                          onChange={(v) => form.setFieldValue(field.name, v)}
                          defaultSelected
                        />
                      </div>
                    }
                    hasError={form.touched.sendInvite && form.errors.sendInvite}
                  />
                )}
              </TypedField>
            </FieldContainer>
            <Rule height={2} />
            <HStack align="end" display="flex" spacing={8} justify="end">
              <CloseDrawerLink>
                <Button variant="secondary" type="button">
                  Cancel
                </Button>
              </CloseDrawerLink>
              <Button variant="primary" type="submit">
                Next
              </Button>
            </HStack>
          </DrawerContent>
        </form>
      )}
    </Formik>
  );
}
